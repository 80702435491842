@charset "UTF-8";
@font-face {
  font-family: "iconfont";
  src: url("/common/fonts/iconfont.eot");
  src: url("/common/fonts/iconfont.eot?#iefix") format("eot"), url("/common/fonts/iconfont.woff2") format("woff2"), url("/common/fonts/iconfont.woff") format("woff"), url("/common/fonts/iconfont.ttf") format("truetype"), url("/common/fonts/iconfont.svg#iconfont") format("svg");
}

html, body {
  overflow: hidden;
}

.cnt_top {
  position: relative;
  overflow: hidden;
  height: calc(100vh - 64px - 45px);
  width: 100%;
  /* min-width:960px;*/
  margin-top: 64px;
  margin-bottom: 45px;
  background-color: #000;
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  /* 変形用ラッパー */
  /* 背景イメージ実体 */
  /* ナビゲーション */
}

@media (max-width: 425px) {
  .cnt_top {
    height: calc(100vh - 50px - 46px);
    min-width: 0;
    margin-top: 50px;
  }
}

.cnt_top--box {
  position: absolute;
  overflow: hidden;
  height: 100%;
  top: 0;
  /*width:62%;*/
  width: 68%;
  transform: skewX(15deg);
  transition: width .3s ease-in-out .2s;
  z-index: 1;
}

@media (max-width: 425px) {
  .cnt_top--box {
    width: 82%;
    /* width:70%; */
    /* transform:skewX(22.5deg); */
  }
}

.cnt_top--left {
  /* calcを使うとIE11でtransitionしない(@_@) */
  /*left:-12vw;*/
  /*width:calc(50% + 12vw);*/
  /*left:-12%;*/
  left: -18%;
  transition: width .3s ease-in-out .2s;
}

@media (max-width: 425px) {
  .cnt_top--left {
    left: -20%;
  }
}

.cnt_top--right {
  /*right:-12%;*/
  right: -18%;
}

@media (max-width: 425px) {
  .cnt_top--right {
    right: -36%;
  }
}

.cnt_top--img {
  opacity: 0;
  position: absolute;
  top: -3px;
  width: 100vw;
  height: calc(100vh - 64px);
  background-color: transparent;
  background-position: center center;
  background-size: cover;
  transition: opacity 1s ease-in-out;
  transform: skewX(-15deg);
}

.cnt_top--img.current {
  opacity: 1;
  z-index: 1;
}

.cnt_top--img-left {
  /*left:calc(12vw - 6px);*/
  left: calc(18vw - 5px);
}

.cnt_top--img-right {
  left: auto;
  /* right:calc(12vw + 5px); */
  right: calc(18vw + 5px);
}

.cnt_top--img-l01 {
  background-image: url("/client/images/main01.jpg");
}

.cnt_top--img-l02 {
  background-image: url("/client/images/main02.jpg");
}

.cnt_top--img-l03 {
  background-image: url("/client/images/main03.jpg");
}

.cnt_top--img-r01 {
  background-image: url("/staff/images/main01.jpg");
}

.cnt_top--img-r02 {
  background-image: url("/staff/images/main02.jpg");
}

.cnt_top--img-r03 {
  background-image: url("/staff/images/main03.jpg");
}

@media (max-width: 425px) {
  .cnt_top--img {
    height: calc(100vh - 50px);
    /* transform:skewX(-22.5deg); */
  }
  .cnt_top--img-left {
    left: 18vw;
  }
  .cnt_top--img-right {
    left: auto;
    right: 38vw;
  }
  .cnt_top--img-l01 {
    background-image: url("/client/images/main01_sp.jpg");
  }
  .cnt_top--img-l02 {
    background-image: url("/client/images/main02_sp.jpg");
  }
  .cnt_top--img-l03 {
    background-image: url("/client/images/main03_sp.jpg");
  }
  .cnt_top--img-r01 {
    background-image: url("/staff/images/main01_sp.jpg");
  }
  .cnt_top--img-r02 {
    background-image: url("/staff/images/main02_sp.jpg");
  }
  .cnt_top--img-r03 {
    background-image: url("/staff/images/main03_sp.jpg");
  }
}

.cnt_top--nav {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  /* min-width:960px; */
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 2;
  text-align: center;
}

@media (max-width: 425px) {
  .cnt_top--nav {
    min-width: 0;
    height: 100%;
  }
}

.cnt_top--nav li {
  margin: 0 auto;
}

.cnt_top--nav:before {
  content: '';
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000 url("/images/top-pattern@2x.png") center center;
  background-size: 380px auto;
  opacity: .15;
  z-index: -1;
}

.cnt_top--btn {
  position: relative;
  display: flex;
  color: #c80023;
  font-size: 24px;
  font-weight: 500;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  width: 316px;
  height: 80px;
  background: #fff;
  border: 2px solid #c80023;
  z-index: 1;
  transition: all .2s linear;
  opacity: 1;
  margin-top: -2vh;
}

.cnt_top--btn:after {
  position: absolute;
  right: 12px;
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  speak: none;
  text-decoration: none;
  text-transform: none;
  content: "";
  font-size: 24px;
}

@media (max-width: 425px) {
  .cnt_top--btn:after {
    font-size: 11px;
    right: 5px;
  }
}

.cnt_top--btn:hover {
  color: #fff;
  background: #c80023;
}

.cnt_top--btn:hover:after {
  color: #fff;
}

@media (max-width: 425px) {
  .cnt_top--btn {
    font-size: 12px;
    width: 150px;
    height: 38px;
    border-width: 1px;
  }
}

/* ホバー・選択時の動作 */
/*.hover_left{
  & .cnt_top--left{width:/82%;}
  & .cnt_top--right{width:42%;}
  & .cnt_top--btn-right{opacity:0;transition-delay:.2s;}
}
.hover_right{
  & .cnt_top--left{width:42%;}
  & .cnt_top--right{width:82%;}
  & .cnt_top--btn-left{opacity:0;transition-delay:.2s;}
}*/
.hover_left .cnt_top--left,
.click_left .cnt_top--left {
  width: 180%;
}

.hover_left .cnt_top--right,
.click_left .cnt_top--right {
  width: 0;
}

.hover_left .cnt_top--btn-right,
.click_left .cnt_top--btn-right {
  opacity: 0;
  transition-delay: .2s;
}

@media (max-width: 425px) {
  .hover_left .cnt_top--left,
  .click_left .cnt_top--left {
    width: 164%;
  }
}

.hover_right .cnt_top--left,
.click_right .cnt_top--left {
  width: 0;
}

.hover_right .cnt_top--right,
.click_right .cnt_top--right {
  width: 132%;
}

.hover_right .cnt_top--btn-left,
.click_right .cnt_top--btn-left {
  opacity: 0;
  transition-delay: .2s;
}

@media (max-width: 425px) {
  .hover_right .cnt_top--right,
  .click_right .cnt_top--right {
    width: 164%;
  }
}

/* footer */
.footer_top {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 100;
  background-color: #c80023;
  padding: 0 0 7px 0;
}

@media (max-width: 425px) {
  .footer_top {
    padding: 0 0 4px 0;
  }
}

.footer_top--inner {
  position: relative;
  display: flex;
  align-items: center;
}

.footer_top--inner:before {
  content: "";
  position: absolute;
  top: 0;
  left: 144px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 42px 26px;
  border-color: transparent transparent #000000 transparent;
}

@media (max-width: 425px) {
  .footer_top--inner:before {
    left: 54px;
    border-width: 0 0 42px 24px;
  }
}

.footer_top--ttl {
  font-family: 'Anton', sans-serif;
  color: #fff;
  font-size: 24px;
  line-height: 1;
  font-weight: normal;
  width: 50px;
  padding: 0 40px 0 80px;
}

@media (max-width: 425px) {
  .footer_top--ttl {
    font-size: 16px;
    padding: 0 24px 0 20px;
    width: 39px;
  }
}

.footer_top--news {
  position: relative;
  background-color: #000;
  width: 100%;
  height: 42px;
  overflow: hidden;
}

.footer_top--news-list {
  position: absolute;
  left: 0;
  top: 0;
  width: auto;
  height: 42px;
  display: flex;
  align-items: center;
  transition: left .3s ease-out;
}

.footer_top--news-item {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 16px;
  display: flex;
  align-items: center;
  width: calc(100vw - 170px);
  height: 42px;
  padding: 0 0 0 20px;
  color: #fff;
  box-sizing: border-box;
}

@media (max-width: 425px) {
  .footer_top--news-item {
    width: calc(100vw - 77px);
    font-size: 14px;
    line-height: 1.2;
    padding: 0;
  }
}

.footer_top--more {
  position: absolute;
  top: 0;
  right: 100px;
  font-family: 'Noto Sans JP', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: 130px;
  height: 42px;
  font-size: 16px;
  text-decoration: none;
  color: #000;
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
  transition: .3s;
}

.footer_top--more:after {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
  margin: auto;
  content: "";
  vertical-align: middle;
  width: 5px;
  height: 5px;
  border-top: 2px solid #000;
  border-right: 2px solid #000;
  transform: rotate(45deg);
  transition: .3s;
}

.footer_top--more:hover {
  color: #fff;
  background-color: #000;
}

.footer_top--more:hover:after {
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
}
