@charset "UTF-8";
@import "../mixin";
@import "../icon";

html,body{
  overflow:hidden;
}

.cnt_top {
  position: relative;
  overflow: hidden;
  height: calc(100vh - 64px - 45px);
  width:100%;
  /* min-width:960px;*/
  margin-top:64px;
  margin-bottom:45px;
  background-color:#000;
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing:border-box;
  @include sp{
    height: calc(100vh - 50px - 46px);
    min-width:0;
    margin-top:50px;
  }
  /* 変形用ラッパー */
  &--box {
    position:absolute;
    overflow:hidden;
    height: 100%;
    top:0;
    /*width:62%;*/
    width:68%;
    transform:skewX(15.00deg);
    transition:width .3s ease-in-out .2s;
    z-index:1;
    @include sp{
      width:82%;
      /* width:70%; */
      /* transform:skewX(22.5deg); */
    }
  }
  &--left{
    /* calcを使うとIE11でtransitionしない(@_@) */
    /*left:-12vw;*/
    /*width:calc(50% + 12vw);*/
    /*left:-12%;*/
    left:-18%;
    transition:width .3s ease-in-out .2s;
    @include sp{
      left:-20%;
    }
  }
  &--right{
    /*right:-12%;*/
    right:-18%;
    @include sp{
      right:-36%;
    }
  }
  /* 背景イメージ実体 */
  &--img {
    opacity:0;
    position:absolute;
    top:-3px;
    width:100vw;
    height:calc(100vh - 64px);
    background-color:transparent;
    background-position:center center;
    background-size:cover;
    transition:opacity 1s ease-in-out;
    transform:skewX(-15.00deg);
    &.current{
      opacity:1;
      z-index:1;
    }
    &-left{
      /*left:calc(12vw - 6px);*/
      left:calc(18vw - 5px);
    }
    &-right{
      left:auto;
      /* right:calc(12vw + 5px); */
      right:calc(18vw + 5px);
    }
    &-l01{background-image:url('/client/images/main01.jpg');}
    &-l02{background-image:url('/client/images/main02.jpg');}
    &-l03{background-image:url('/client/images/main03.jpg');}
    &-r01{background-image:url('/staff/images/main01.jpg');}
    &-r02{background-image:url('/staff/images/main02.jpg');}
    &-r03{background-image:url('/staff/images/main03.jpg');}
    @include sp{
      height:calc(100vh - 50px);
      /* transform:skewX(-22.5deg); */
      &-left{
        left:18vw;
      }
      &-right{
        left:auto;
        right:38vw;
      }
      &-l01{background-image:url('/client/images/main01_sp.jpg');}
      &-l02{background-image:url('/client/images/main02_sp.jpg');}
      &-l03{background-image:url('/client/images/main03_sp.jpg');}
      &-r01{background-image:url('/staff/images/main01_sp.jpg');}
      &-r02{background-image:url('/staff/images/main02_sp.jpg');}
      &-r03{background-image:url('/staff/images/main03_sp.jpg');}
    }
  }
  /* ナビゲーション */
  &--nav{
    position:absolute;
    left:0;
    top:0;
    width:100vw;
    /* min-width:960px; */
    height:100%;
    display:flex;
    align-items:center;
    z-index:2;
    text-align:center;
    @include sp{
      min-width:0;
      height:100%;
    }
    & li{
        margin:0 auto;
    }
    &:before{
      content:'';
      position:fixed;
      left:0;
      top:0;
      width:100%;
      height:100%;
      background:#000 url('/images/top-pattern@2x.png') center center;
      background-size:380px auto;
      opacity:.15;
      z-index:-1;
    }
  }
  &--btn{
    position:relative;
    display:flex;
    color:$base-red;
    font-size:24px;
    font-weight:500;
    text-decoration:none;
    justify-content:center;
    align-items:center;
    width:316px;
    height:80px;
    background:#fff;
    border:2px solid $base-red;
    z-index:1;
    transition:all .2s linear;
    opacity:1;
    margin-top:-2vh;
    &:after{
      position:absolute;
      right:12px;
      @include icon;
      content:$icon-arrow-right;
      font-size:24px;
      @include sp{
        font-size:11px;
        right:5px;
      }
    }
    &:hover{
      color:#fff;
      background:$base-red;
      &:after{
        color:#fff;
      }
    }
    @include sp{
      font-size:12px;
      width:150px;
      height:38px;
      border-width:1px;
    }
  }
}

/* ホバー・選択時の動作 */
/*.hover_left{
  & .cnt_top--left{width:/82%;}
  & .cnt_top--right{width:42%;}
  & .cnt_top--btn-right{opacity:0;transition-delay:.2s;}
}
.hover_right{
  & .cnt_top--left{width:42%;}
  & .cnt_top--right{width:82%;}
  & .cnt_top--btn-left{opacity:0;transition-delay:.2s;}
}*/

.hover_left,
.click_left{
  & .cnt_top--left{width:/*calc(120% + 12vw);132%;*/180%;}
  & .cnt_top--right{width:0;}
  & .cnt_top--btn-right{opacity:0;transition-delay:.2s;}
  @include sp{
    & .cnt_top--left{width:164%;}
  }
}
.hover_right,
.click_right{
  & .cnt_top--left{width:0;}
  & .cnt_top--right{width:132%;}
  & .cnt_top--btn-left{opacity:0;transition-delay:.2s;}
  @include sp{
    & .cnt_top--right{width:164%;}
  }
}


/* footer */
.footer_top {
  position: fixed;
  left:0;
  bottom:0;
	width: 100%;
	z-index: 100;
	background-color: $base-red;
  padding: 0 0 7px 0;
  @include sp{
    padding:0 0 4px 0;
  }
  &--inner {
    position:relative;
    display: flex;
    align-items: center;
    &:before {
      content:"";
      position: absolute;
      top:0;
      left:144px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 42px 26px;
      border-color: transparent transparent #000000 transparent;
      @include sp{
        left:54px;
        border-width:0 0 42px 24px;
      }
    }
  }
  &--ttl {
    font-family: 'Anton', sans-serif;
    color: #fff;
    font-size: 24px;
    line-height:1;
    font-weight: normal;
    width:50px;
    padding: 0 40px 0 80px;
    @include sp{
      font-size:16px;
      padding:0 24px 0 20px;
      width:39px;
    }
  }
  &--news {
    position: relative;
    background-color: #000;
    width: 100%;
    height: 42px;
    overflow:hidden;
     &-list{
      position:absolute;
      left:0;
      top:0;
      width:auto;
      height: 42px;
      display: flex;
      align-items: center;
      transition:left .3s ease-out;
    }
    &-item {
      font-family: 'Noto Sans JP', sans-serif;
      font-size: 16px;
      display:flex;
      align-items:center;
      width:calc(100vw - 170px);
      height:42px;
      padding: 0 0 0 20px;
      color: #fff;
      box-sizing:border-box;
      @include sp{
        width:calc(100vw - 77px);
        font-size:14px;
        line-height:1.2;
        padding:0;
      }
    }
  }
  &--more {
    position: absolute;
    top:0;
    right:100px;
    font-family: 'Noto Sans JP', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: 130px;
    height: 42px;
    font-size: 16px;
    text-decoration: none;
    color: #000;
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
    transition: .3s;
    &:after {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 10px;
      margin: auto;
      content: "";
      vertical-align: middle;
      width: 5px;
      height: 5px;
      border-top: 2px solid #000;
      border-right: 2px solid #000;
      transform: rotate(45deg);
      transition: .3s;
    }
    &:hover {
      color: #fff;
      background-color: #000;
      &:after {
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
      }
    }
  }
}